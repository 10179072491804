<template>
  <div>
    <b-row class="mt-0 mt-lg-3">
      <b-col xl="3">
        <card :card="mod" :link="false" :seeMore="true" />
        <div class="mt-8">
          <div
            class="card card-custom card-stretch gutter-b tc4"
            style="height: 210px; border-radius: 30px;"
            v-if="socialEngineering"
          >
            <!--begin::Body-->
            <div class="card-body">
              <div
                class="d-flex align-items-center p-5"
                style="min-height: 100%"
              >
                <div class="d-flex flex-column" style="width: 100%;">
                  <span>
                    <span class="text-dark font-weight-bold font-size-h4">
                      {{ $t("profile.yourProfile") }}
                    </span>
                    <br />
                    <span class="text-dark font-weight-bolder font-size-h2">
                      {{ socialEngineering }}
                    </span>
                  </span>
                </div>
              </div>
              <!--begin::Chart-->
              <h1></h1>
              <!--end::Chart-->
            </div>
            <!--end::Body-->
          </div>
        </div>
      </b-col>
      <b-col cols="9">
        <b-card
          class="card-custom gutter-b"
          body-class="p-0 d-flex flex-column"
          header-class="border-0 pt-5"
        >
          <template #header>
            <b-card-title class="font-weight-bolder">
              <div class="card-label">
                {{ $t("profile.socialEngineering.help.title") }}
              </div>
            </b-card-title>
          </template>
          <div class="card-body text-left">
            <p
              v-for="(value, key) in $t(
                'profile.socialEngineering.help.description'
              )"
              :key="key"
              v-html="value"
            />
          </div>
        </b-card>
        <tabs
          :tabs="tabs"
          :title="$t('generic.modules.social.title')"
          v-if="isProfileLoaded && tabs.length > 0"
          :stretch="false"
        />
        <empty-card type="profile.socialEngineering" v-else />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { mapActions, mapGetters } from "vuex";

import { getUserActions } from "@/api/user/traits.api";

import Card from "@/components/profile/Card.component";
import Tabs from "@/components/training/Tabs.component";
import EmptyCard from "@/components/EmptyCard.component.vue";

export default {
  name: "SocialEngineering",
  components: {
    Card,
    Tabs,
    EmptyCard
  },
  methods: {
    ...mapActions("Profile", ["loadProfile"]),
    loadRecommendations() {
      var params = {
        surveyTypes: [
          this.getConstant("SURVEY_KYMATIO_SOCIAL_ENGINEERING_GLOBAL")
        ],
        groupDimensions: [
          this.getConstant("DIMENSION_GROUP_SOCIAL_ENGINEERING")
        ],
        actionTypes: [
          this.getConstant("ACTIONS_TYPE_SE_TYPE_RECOM"),
          this.getConstant("ACTIONS_TYPE_SE_RECOMENDATION"),
          this.getConstant("ACTIONS_TYPE_SE_GENERAL")
        ],
        entityType: this.getConstant("ENTITY_TYPE_USER"),
        maxValue: "NO"
      };
      var paramsName = {
        surveyTypes: [
          this.getConstant("SURVEY_KYMATIO_SOCIAL_ENGINEERING_GLOBAL")
        ],
        groupDimensions: [
          this.getConstant("DIMENSION_GROUP_SOCIAL_ENGINEERING")
        ],
        actionTypes: [this.getConstant("ACTIONS_TYPE_SE_NAME")],
        entityType: this.getConstant("ENTITY_TYPE_USER"),
        maxValue: "NO"
      };
      axios.all([getUserActions(params), getUserActions(paramsName)]).then(
        axios.spread((res, resName) => {
          var tabs = res.data.records;
          _.each(tabs, tab => {
            switch (parseInt(tab.actionTypeId)) {
              case parseInt(this.getConstant("ACTIONS_TYPE_SE_TYPE_RECOM")):
                tab.order = 0;
                break;
              case parseInt(this.getConstant("ACTIONS_TYPE_SE_RECOMENDATION")):
                tab.order = 1;
                break;
              case parseInt(this.getConstant("ACTIONS_TYPE_SE_GENERAL")):
                tab.order = 2;
                break;
              default:
                tab.order = 0;
            }
            this.tabs = _.orderBy(tabs, "order");
          });
          this.socialEngineering = _.find(resName.data.records, action => {
            return (
              parseInt(action.actionTypeId) ===
              parseInt(this.getConstant("ACTIONS_TYPE_SE_NAME"))
            );
          });
          this.socialEngineering = this.socialEngineering
            ? this.socialEngineering.executeAction.title
            : false;
        })
      );
    }
  },
  data() {
    return {
      object: {},
      tabs: [],
      socialEngineering: false
    };
  },
  computed: {
    ...mapGetters("Profile", ["isProfileLoaded", "modules"]),
    ...mapGetters("Constants", ["isConstantsActive", "getConstant"]),
    mod() {
      return _.find(this.modules, e => {
        return e.id === "social";
      });
    }
  },
  mounted() {
    if (this.isConstantsActive && !this.isProfileLoaded) this.loadProfile();
    if (this.isConstantsActive) this.loadRecommendations();
  },
  watch: {
    isConstantsActive(value) {
      if (value === true) {
        this.loadProfile();
        this.loadRecommendations();
      }
    }
  }
};
</script>
